<template>
  <div>
    <div style="display: flex">
      <kafkaSelect @kafkaChange="kafkaChange"></kafkaSelect>
      <div>
        <el-input
          placeholder="搜索管道"
          v-model="keyword"
          style="width: 250px; margin-left: 20px"
          clearable
          @keyup.enter.native="search"
          @clear="search"
        >
          <el-button @click="search" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>

    <div>
      <el-table
        :data="tableData"
        v-loading="loading"
        stripe
        border
        size="small"
      >
        <el-table-column prop="topic" label="管道名称"></el-table-column>
        <el-table-column prop="source" label="数据源"> </el-table-column>
        <el-table-column prop="consumer" label="消费者"></el-table-column>
        <el-table-column prop="offset" label="数据量"> </el-table-column>
        <el-table-column
          prop="rate"
          label="平均速率"
          :formatter="rateFormatter"
        >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              round
              type="info"
              @click="getConsumerInfo(scope.row)"
            >
              查看消息
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-size="pageSize"
        :total="totalCount"
        layout="total, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>

    <consumer ref="consumer"></consumer>
  </div>
</template>

<script>
import kafkaSelect from "../kafkaSelect.vue";
import dataTag from "../common/dataTag.vue";
import GroupTable from "../common/GroupTable.vue";
import consumer from "../common/consumer.vue";
export default {
  name: "topic",
  data() {
    return {
      id: undefined,
      tableData: [],
      keyword: undefined,
      timer: undefined,
      loading: false,
      pageIndex: 1,
      totalCount: 0,
      pageSize: 10,
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.id && this.getTopicDetailById();
    }, 30000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.timer);
    });
  },
  methods: {
    async getTopicDetailById() {
      const {data} = await this.$http({
        url: this.$http.adornUrl("/kafka/getTopicDetailById"),
        method: "get",
        params: this.$http.adornParams({
          id: this.id, 
          pageSize: this.pageSize,
          currPage: this.pageIndex,
          key: this.keyword
        }),
      });
      if (data?.code == 500) {
        this.$message.error(data.msg);
      } else {
        this.tableData = data.data.list;
        this.totalCount = data.data.totalCount;
      }
    },
    async kafkaChange(id) {
      if (id == null || id == undefined) {
        this.tableData = [];
        this.totalCount = 0;
        return;
      }
      this.id = id;
      this.loading = true;
      await this.getTopicDetailById();
      this.loading = false;
    },
    rateFormatter(row) {
      let rate= Number(row.rate) * 60;
      if (rate < 0.00001) {
        return 0;
      } else {
        return rate;
      }
    },
    getConsumerInfo({topic}) {
      this.$refs.consumer.init(topic, this.id);
    },
    async currentChangeHandle(val) {
      this.pageIndex = val;
      this.loading = true;
      await this.getTopicDetailById();
      this.loading = false;
    },
    async search() {
      this.pageIndex = 1;
      this.loading = true;
      await this.getTopicDetailById();
      this.loading = false;
    }
  },
  components: {
    kafkaSelect,
    dataTag,
    GroupTable,
    consumer,
  },
};
</script>

<style scoped>
i {
  font-size: 13px;
}

li {
  border-bottom: 1px solid #000000;
}

div {
  margin-bottom: 5px 0;
}
</style>
