<template>
  <el-dialog
    :title="'消息'"
    :visible.sync="visible"
    :before-close="stop"
    width="70%"
  >
    <div class="frame">
      <div class="left">
        <i
          :class="['el-icon-bottom', { active: autoScrollToBottom }]"
          @click="autoScroll"
        ></i>
        <i
          :class="['el-icon-s-fold', { active: autoBreak }]"
          @click="autoChangeLine"
        ></i>
        <i class="el-icon-delete" @click="clear"></i>
      </div>
      <div class="right" ref="frame">
        <div v-for="(item, index) in message" :key="index" class="history">
          <div class="index">
            <div class="index-c">{{ index + 1 }}</div>
          </div>
          <div
            :class="['message', { autoBreak: autoBreak }]"
            v-html="item"
          ></div>
        </div>
        <p><i class="el-icon-loading" v-if="on"></i></p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dataTag from "./dataTag";

export default {
  name: "consumer.vue",
  data() {
    return {
      visible: false,
      on: false,
      message: [],
      websocket: null,
      autoScrollToBottom: true,
      autoBreak: true,
      consumeCount: 0,
      topic: "",
      id: undefined
    };
  },
  watch: {
    message() {
      if (this.autoScrollToBottom) {
        this.$nextTick(() => {
          this.$refs.frame.scrollTop = this.$refs.frame.scrollHeight;
        });
      }
    },
  },
  methods: {
    init(topicName, id) {
      this.visible = true;
      this.$nextTick(() => {
        this.topic = topicName;
        this.id = id;
        this.clear();
        this.start();
      });
    },
    autoScroll() {
      this.autoScrollToBottom = !this.autoScrollToBottom;
      if (this.autoScrollToBottom) {
        this.$nextTick(() => {
          this.$refs.frame.scrollTop = this.$refs.frame.scrollHeight;
        });
      }
    },
    autoChangeLine() {
      this.autoBreak = !this.autoBreak;
    },
    clear() {
      this.message = [];
    },
    start() {
      this.consumeCount = 0;
      if ("WebSocket" in window) {
        let url = `ws://${this.$store.state.websocket.ip}:${this.$store.state.websocket.port}/kafka/websocket/${this.topic}/${this.id}`;
        this.websocket = new WebSocket(url);
        this.initWebSocket();
      }
    },
    stop(done) {
      console.log("stop");
      if (this.websocket != null) {
        this.websocket.close();
      }
      done();
    },
    scroll() {
      this.$nextTick(() => {
        console.log('this.$refs.frame :>> ', this.$refs.frame);
        this.$refs.frame.scrollTop = 100000;
      });
    },
    initWebSocket() {
      // 连接错误
      this.websocket.onerror = () => {
        console.log(
          "WebSocket连接发生错误   状态码：" + this.websocket.readyState
        );
      };
      // 连接成功
      this.websocket.onopen = () => {
        console.log(
          "WebSocket连接成功    状态码：" + this.websocket.readyState
        );
      };
      // 收到消息的回调
      this.websocket.onmessage = (event) => {
        this.consumeCount = this.consumeCount + 1;
        this.message.push(event.data);
        if (this.autoScrollToBottom) this.scroll();
      };
      // 连接关闭的回调
      this.websocket.onclose = () => {
        console.log(
          "WebSocket连接关闭    状态码：" + this.websocket.readyState
        );
      };
      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = () => {
        this.websocket.close();
      };
    },
  },
  components: { dataTag },
};
</script>

<style scoped lang="scss">
.label {
  margin-right: 1px;
  padding: 0 10px;
  line-height: 40px;
  background-color: #06b176;
  color: #fff;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
}
.frame {
  display: flex;
  min-height: calc(100vh - 350px);
  max-height: 600px;
  box-shadow: 1px 1px 5px #72767b;
  margin-bottom: 5px;

  .left {
    width: 30px;
    font-size: 30px;
    border-right: #8c939d 1px solid;
    background-color: #edebeb;
    display: flex;
    flex-direction: column;

    i {
      font-size: 20px;
      text-align: center;
      font-weight: 900;
      margin: 1px;
      color: #151313;
      border-radius: 4px;
    }

    i:hover {
      background-color: #cacac6;
    }

    .active {
      background-color: #ababa7;
    }
  }

  .right {
    overflow-y: scroll;

    width: 100%;
    background-color: #fbf7f7;

    .history {
      margin: 3px;
      background-color: #fde6c0;
      display: flex;
      //flex-wrap: wrap;

      .index {
        //font-weight: 900;
        width: 25px;
        background-color: #f6bf6a;

        //margin-right: 3px;
      }

      .index-c {
        width: 25px;
        font-size: 10px;
      }

      .message {
        //background-color: #fde6c0;
        padding: 0 5px;
        white-space: pre-line; //  字符串\n换行
      }

      .autoBreak {
        overflow: hidden;
        overflow-wrap: break-word;
      }
    }

    //.autoBreak {
    //  overflow-wrap: break-word;
    //}
  }
}
</style>
